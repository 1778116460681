.cs-sidebar,
.cs-main-container,
.cs-conversation-list,
.scrollbar-container,
.cs-conversation,
.cs-search,
.cs-message--incoming > .cs-message__content-wrapper > .cs-message__content,
.cs-message-input--disabled,
.cs-chat-container,
.cs-chat-container .cs-message-input,
.cs-conversation:hover {
  background-color: var(--paper);
}
.cs-message-input__content-editor-wrapper,
.cs-message-input__content-editor-container,
.cs-message-input__content-editor {
  background-color: var(--paper-secondary);
}
.cs-conversation__name,
.cs-conversation__info-content,
.cs-conversation__last-sender,
.cs-search__input,
.cs-search__input::placeholder,
.cs-message-input__content-editor,
.cs-message-input__content-editor::before,
.cs-message-input__content-editor[data-placeholder]:empty:before {
  color: var(--paper-contrast-text);
}

.cs-message-input__content-editor-wrapper
  > .cs-message-input__content-editor-container
  > .cs-message-input__content-editor,
.cs-conversation,
.cs-conversation-header,
.cs-message-list,
.cs-message--incoming > .cs-message__content-wrapper > .cs-message__content,
.cs-message--outgoing > .cs-message__content-wrapper > .cs-message__content,
.cs-button--attachment {
  font-family: var(--font-family);
}

.ps__rail-y {
  width: 5px !important;
  background-color: var(--paper) !important;
}
.ps__thumb-y {
  background-color: var(--paper-secondary) !important;
  width: 5px !important;
  right: 0px !important;
}

.cs-main-container {
  border: none;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  font-family: var(--font-family);
  width: 100%;
}

.cs-chat-container {
  padding: 4px 12px 12px 0px;
}
.chat-bot > .cs-conversation__content > .cs-conversation__name {
  font-weight: 900;
  color: var(--secondary-color);
}
.cs-sidebar {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-right: 1px solid var(--paper-secondary);
}
.cs-search-container {
  padding: 4px 12px 0px 12px;
}
.cs-search {
  background-color: var(--paper) !important;
  margin: 4px 8px;
  padding: 6px;
  width: 100%;
}
.cs-search__input {
  background-color: var(--paper) !important;
  font-size: 0.9em;
}
.cs-search__search-icon,
.cs-search__clear-icon {
  color: var(--paper-contrast-text) !important;
}
.cs-conversation {
  padding: 0.675em 1.2em 0.675em 1.2em;
}
.cs-conversation-header,
.cs-conversation-header__user-name {
  background-color: var(--paper) !important;
}
.cs-conversation:hover {
  filter: var(--hover-main);
}
.cs-conversation__content {
  margin-right: 0;
}
.cs-conversation > .cs-avatar {
  margin-right: 0.9em;
}
.cs-message > .cs-message__content-wrapper > .cs-message__content {
  border-radius: 20px;
}
.cs-message--incoming > .cs-message__content-wrapper > .cs-message__content {
  color: var(--paper-contrast-text);
}
.cs-message--outgoing > .cs-message__content-wrapper > .cs-message__content {
  background-color: inherit;
  color: var(--paper-contrast-text);
}
.cs-message__content {
  padding: 0;
}

.cs-message__avatar > .cs-avatar > img {
  object-fit: cover !important;
  border-radius: 50%;
}
.cs-message-list .cs-typing-indicator {
  height: 1px;
  background-color: var(--paper);
  padding: 0.5em 1.5em;
}
.cs-typing-indicator__dot {
  background-color: #858080 !important;
}
.cs-message-input {
  padding: 0 !important;
  margin: 0;
  border-radius: 20px;
  flex-shrink: unset;
  max-height: 80px;
}
.cs-message-input__content-editor {
  min-height: 80px;
}
.cs-message-input__content-editor-wrapper {
  margin: 0 !important;
  border-radius: 20px;
}
.cs-message-input__tools {
  margin-right: 4px;
  margin-left: 8px;
}
.cs-button--send,
.cs-button--attachment {
  background-color: black !important;
  color: white;
}

.cs-conversation__unread-dot {
  background: #ff0000;
}

@media (max-width: 600px) {
  .cs-main-container {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .cs-sidebar {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
