.vjs-fullscreen > .vjs-tech {
  object-fit: contain !important;
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.vjs-waiting {
  max-width: 100% !important;
}
.vjs-waiting > .vjs-tech {
  max-width: 100% !important;
}

body {
  padding-right: 0px !important;
}

.swal2-success-circular-line-left,
.swal2-success-circular-line-right,
.swal2-success-fix {
  background-color: transparent !important;
}

.card {
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.card .choice {
  position: absolute;
  top: 20px;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.card .choice.like {
  left: 20px;
  color: green;
}

.card .choice.nope {
  right: 20px;
  color: red;
}

.card.go-right,
.card.go-left {
  transition: transform 0.5s ease;
}

.card.go-right {
  transform: translateX(1000px) rotate(60deg);
}

.card.go-left {
  transform: translateX(-1000px) rotate(-60deg);
}

.card.reset {
  transition: transform 0.5s ease;
}

.disable-text-selection {
  user-select: none; /* Establece que el texto no se puede seleccionar */
  -webkit-user-select: none; /* Para navegadores basados en WebKit (como Chrome y Safari) */
  -moz-user-select: none; /* Para navegadores basados en Gecko (como Firefox) */
  -ms-user-select: none; /* Para Internet Explorer */
}
